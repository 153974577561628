import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_129, web_134, web_16_information_board } from '../../api/api';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';
import useSystemText from '../../helper/useSystemText';
import { MultiSelect } from 'primereact/multiselect';
import useTableSize from '../../helper/useTableSize';
import { Dropdown } from 'primereact/dropdown';

const MediatorDailyResult = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [dailyResultData , setDailyResultData] = useState([]) 
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [filterDate, setFilterDate] = useState([])
  const tableScrollY = useTableSize(70, 50)
  const [mediatorListData, setMediatorListData] = useState([])
  const [selectMediator, setSelectMediator] = useState("")

  const sysText801001 = useSystemText(801001)
  const sysText801002 = useSystemText(801002)
  const sysText801005 = useSystemText(801005)
  const sysText801006 = useSystemText(801006)
  const sysText801008 = useSystemText(801008)
  const sysText801009 = useSystemText(801009)
  const sysText801010 = useSystemText(801010) 
  const sysText805001 = useSystemText(805001)
  

  //預設時間為6個月前
  const today = new Date()

  const defaultDate = [
    new Date(today.setHours(0, 0, 0, 0)), // Today's start date (00:00:00)
    new Date(today.setHours(23, 59, 59, 999)) // Today's end date (23:59:59)
  ]  

  const merchantCodeColumn = (row) => {
    const newArray = row.商戶帳號 ? row.商戶帳號.split(",") : []
    return (
      <div>
          {newArray.map((item, index) => (
              <p key={index}>{item}</p> 
          ))}
      </div>
    );
  }

  const tableColumns = [
    {
      header: sysText801001,
      field: "",
      style: {minWidth: "90px"},
      body: merchantCodeColumn
    },
    {
      header: sysText801002,
      field: "商戶號",
      style: {minWidth: "140px"},
    },   
    {
      header: sysText801005,
      field: "訂單成功金額",
      style: {minWidth: "140px"},
      sortable: true
    },
    {
      header: sysText801006,
      field: "訂單成功單數",
      style: {minWidth: "140px"},
      sortable: true
    },   
    {
      header: sysText801008,
      field: "採購成功單數",
      style: {minWidth: "140px"},
      sortable: true
    },
    {
      header: sysText801009,
      field: "採購駁回金額",
      style: {minWidth: "140px"},
      sortable: true
    },
    {
      header: sysText801010,
      field: "採購成功金額",
      style: {minWidth: "140px"},
      sortable: true
    },   
    {
      header: sysText805001,
      field: "中間人費用",
      style: {minWidth: "140px"},
      sortable: true
    },   

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })  

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {         

    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      start_date: filterDate[0] ? filterDate[0] : defaultDate[0].getTime(),
      end_date: filterDate[1] ? filterDate[1] : today.getTime(),
    };

    const fetchMediatorData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_129(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setMediatorListData(listResponse.map(row => ({name: `${row.uuid} - ${row.username}`, value: row.uuid})))
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
    if(permissions === 9) {
      fetchMediatorData(filterData)
    }
  }, [filterDate, isRefresh]); 

  useEffect(() => {         

    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      start_date: filterDate[0] ? filterDate[0] : defaultDate[0].getTime(),
      end_date: filterDate[1] ? filterDate[1] : today.getTime(),
      mediator_uuid: selectMediator
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_134(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setDailyResultData(listResponse)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    if(permissions === 9 && selectMediator) {
      fetchData(filterData)
    }else if(permissions != 9) {
      fetchData(filterData)
    }
  }, [filterDate, isRefresh, selectMediator]);   

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showSearchInput={false} setFilterDate={setFilterDate} defaultDate={defaultDate}>
          {permissions === 9 &&
            <Dropdown className='custom-dropdown mx-2' value={selectMediator} onChange={(e) => {setSelectMediator(e.value)}} options={mediatorListData} optionLabel='name' optionValue='value'></Dropdown>
          }
          <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
        </FilterInput>
        <div className=''>
          <DataTable value={dailyResultData} scrollable scrollHeight={tableScrollY}>
            {visibleColumns.map((row,index) =>                                
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} sortable={row.sortable}></Column>              
            )}              
          </DataTable>       
        </div>
        
      </div>
  );
}

export default MediatorDailyResult