import React, { useState, useContext, useEffect } from 'react';
import MenuContext from '../../context/menuContext';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import ToastContext from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Button } from 'primereact/button';
import { web_79_get_menu } from '../../api/api';
import MenuSettingConfirm from '../../components/other/menuSetting/menuSettingConfirm';
import MenuSettingRestore from '../../components/other/menuSetting/menuSettingRestore';
import useSystemText from '../../helper/useSystemText';

const MenuSetting = () => {

  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [menuPermissionData, setMenuPermissionData] = useState([])
  const location = useLocation()
  const {menuData} = useContext(MenuContext)
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmData, setConfirmData] = useState({})
  const [showRestore, setShowRestore] = useState(false)



  const sysText10045 = useSystemText(10045)
  const sysText709001 = useSystemText(709001)
  const sysText709002 = useSystemText(709001)


  const restoreHandler = (e) => {
    e.preventDefault()
    setShowRestore(true)
  }

  //data 渲染初始值，避免從undefined變為定義值報錯
  const formik = useFormik({
    initialValues: {
      uuid: "",
      token: "",
      data: menuData.reduce((data, sub) => {
        data[sub.id] = "";
        sub.items && sub.items.forEach(subItem => {
          data[subItem.id] = "";
        });
        return data;
      }, {})
    },
    
    validate: (data) => {
      
      let errors = {};
      const regex = /^(\d,)*\d$/;

      Object.keys(data.data).map(key => {
        if(data.data[key] && !regex.test(data.data[key])) {
          errors[key] = "請符合格式 ex: 1,2,3"
        }
      })  
      // console.log(errors);   

      return errors;
    },
    onSubmit: (data) => {
      setConfirmData(data)
      setShowConfirm(true)        
    }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched.data && formik.touched.data[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	 
  
  useEffect(() => {    

    const fetchData = async() => {
      setIsLoading(true)   
      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")       
      };
         
      try {
        const { listResponse, status, statusText } = await web_79_get_menu(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {
          // 用來跟menuData比對找出原始權限
          setMenuPermissionData(listResponse)
          // 將web79回傳的陣列轉換為 {id: 現在權限} json格式
          const data = listResponse.reduce((obj, item) => {
            obj[item.id] = item.現在權限 || ""
            return obj;
          }, {})
          formik.setValues({data: data})
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
    
  }, [isRefresh, location])

  return (
    <div className='p-4 overflow-scroll h-[85vh]'>  
      <form onSubmit={formik.handleSubmit} className=''>
        {menuData && menuData.map((sub) => {
          return (
            <div key={sub.label} className='flex flex-col my-4'>
              <div className="flex flex-row items-center space-x-2 w-full">
                <label htmlFor={`data[${sub.id}]`} className='text-center w-32 text-blue-300 rounded-lg border border-blue-900 bg-gray-900 underline px-2 py-1'>{sub.label}</label>
                {sub.id != 0 ?
                  <input name={`data[${sub.id}]`}  value={formik.values.data[sub.id]} onChange={formik.handleChange} type="text" className='w-48' autoComplete='off'></input>
                  :
                  <input value={"ALL"} type="text" className='w-48 opacity-70 cursor-not-allowed' disabled={true} autoComplete='off'></input>
                }
              </div>
              <div className='flex flex-col'>
                {menuPermissionData.find(item => item.id == sub.id) ? 
                  <small className='text-yellow-500'>{sysText709001}{menuPermissionData.find(item => item.id == sub.id).原始權限}</small>
                  : null
                }
                {getFormErrorMessage(sub.id)}              
              </div>
             
              <div className="flex flex-row flex-wrap">
                {sub.items?.map((subItem) => {
                  return (
                    <div key={subItem.label} className='w-[30%] max-xl:w-[40%] max-md:w-[100%]'>
                      <div className="flex flex-row items-center space-x-2">
                        <label htmlFor={`data[${subItem.id}]`} className='w-32'>{subItem.label}</label>
                        <input name={`data[${subItem.id}]`}  value={formik.values.data[subItem.id]} onChange={formik.handleChange} type="text" className='w-48' autoComplete='off'></input>            
                      </div>
                      <div className='flex flex-row'>                        
                        {menuPermissionData.find(item => item.id == subItem.id) ? 
                          <small className='text-yellow-500 w-32'>{sysText709001}{menuPermissionData.find(item => item.id == subItem.id).原始權限}</small>
                          : null
                        }
                        {getFormErrorMessage(subItem.id)}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>              
          )
        })}        
        <div className='space-x-4'>
          <Button type="submit" label={sysText10045} severity='info'></Button>
          <Button onClick={(e) => restoreHandler(e)} label={sysText709002} severity='danger'></Button>
        </div>        
      </form>

      {/* 保存設定 */}
      {showConfirm && <MenuSettingConfirm setShowConfirm={setShowConfirm} confirmData={confirmData}/>}
      {/* 還原預設權限 */}
      {showRestore && <MenuSettingRestore setShowRestore={setShowRestore}/>}


     
      
    </div>
  );
};

export default MenuSetting;