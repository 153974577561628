import React, { useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_80_reset_menu } from '../../../api/api';
import useSystemText from '../../../helper/useSystemText';

const MenuSettingRestore = (props) => {
  const {setShowRestore} = props
  
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText709005 = useSystemText(709005)
  const sysText709006 = useSystemText(709006)

  const restoreHandler = async () => {
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")      
    };    

    const updateMenu = async () => {       
      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await web_80_reset_menu(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {   
          setShowRestore(false)
          setIsRefresh(prev => !prev)                  
          showToast("success", "" + status, `${sysText709005}` )
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    updateMenu()    
  } 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[300px] max-md:w-full bg-customColor-bgGray rounded-lg p-4'>
          <div className='flex flex-col'>
            
            <div className='p-4'>             
              <p className='text-red-500'>{sysText709006}</p>
            </div>            
            
            <div className='border-gray-500 flex justify-end items-center space-x-2'>
              <Button style={{width: '80px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowRestore(false)}></Button>
              <Button style={{width: '80px'}} label={sysText10002} severity='info' raised onClick={restoreHandler}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default MenuSettingRestore;