import { TOTP } from "otpauth";

// api
export const API_URL = "https://apiinternal.boncpu.cc";
export const WEB_API_URL = "https://apiweb.boncpu.cc";
export const SMS_API_URL = "https://smsapi.boncpu.cc";
export const FILE_API_URL = "https://img.water168.shop";
export const CONVER_API_URL = "https://cssys.boncpu.cc"
export const WEB_01_SECRET = "MF3WMZ3BIIRV4QCCENPEEI26IIQSGXSCKIRVEQSGLJMECRA="
export const CLOUDFLARE_KEY = "0x4AAAAAAA0WuD3SoftsVBbR"

//將參數判斷number轉str
const convertNumbersToStrings = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // 检查属性值是否为数字
      if (typeof value === 'number') {
        // 转换为字符串
        newObj[key] = value.toString();
      } else {
        // 保留其他类型的值不变
        newObj[key] = value;
      }
    }
  }
  return newObj;
};

//检查登录状态
// web_01_login
export async function checkLoginStatus(userData) {

  userData = convertNumbersToStrings(userData)
  const { cookie_hash, cookie_uuid, tp_password } = userData;
  // const web_01_login_password = 'iQ3JSMvwvfgEUf92YWAACUMtkxyeJXfGmvqJtSGW'

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_01_login`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: tp_password, cookie_hash, cookie_uuid, user: '', user_password: '', verification_code: '' }),
    });

    clearTimeout(time);

    if (response.ok) {
      const user = await response.json();
      return { user, status: response.status };
    }
    throw response;  

  } catch (error) {
    console.log(error)
    return error
  }
}

// 登录
// /web01_login 登录
export async function web_01_login(userData) {

  userData = convertNumbersToStrings(userData)
  const { user, user_password, verification_code, tp_password, captcha } = userData;
  // const web_01_login_password = 'iQ3JSMvwvfgEUf92YWAACUMtkxyeJXfGmvqJtSGW'
  let error;

  for (let i = 0; i < 4; i++) {
    try {
      const controller = new AbortController();
      const time = setTimeout(() => controller.abort(), 30000);

      const response = await fetch(`${WEB_API_URL}/web_01_login`, {
        method: "POST",
        signal: controller.signal,
        headers: {
          "accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user, user_password, cookie_hash: '', cookie_uuid: '', verification_code, password: tp_password, captcha }),
      });
      clearTimeout(time);
      const listResponse = await response.json();
      if (response.ok) {           
        return { listResponse: listResponse, status: response.status };
      }else {
        return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
      }
    } catch (err) {
      // 如果捕獲到異常，也添加額外的資訊
      console.log(error)
      return error;
    }
  } 
}

// export async function f8_orderlist(filterData) {
//   const { ordernumber, start_date, end_date, page, limit } = filterData;
//   const f8_orderlist_password = '9TuWgRu0J3bWca5eJWsWbSxv0ti6qkaHI1zsQORB'

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${WEB_API_URL}/f8_orderlist`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ password: f8_orderlist_password, ordernumber,  start_date, end_date, page, limit }),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();      
//       return { listResponse: listResponse, status: response.status };
//     }
//     const {status, statusText} = response
//     throw new Error({status, statusText});

//   } catch (error) {
//     console.log(error)
//     return error;
//   }  
// }

export async function web_12_orderlist(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, start_date, end_date, page, limit } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_12_orderlist`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber, start_date, end_date, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}


export async function web_09_pur_orderlist(filterData) {

  filterData = convertNumbersToStrings(filterData)

  const { cookie_uuid, cookie_hash, pur_ordernumber, start_date, end_date, pur_bankusername, pur_merchantno, page, limit } = filterData;
  // const web_09_pur_orderlist_password = '4Ny&Cqo9p$YN^obXqBEnHWM6doBxvM88&aLI5Kf#'

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_09_pur_orderlist`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_ordernumber, start_date, end_date, pur_bankusername, pur_merchantno, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

// export async function c7_find_pur_order_card(filterData) {
//   const {uuid} = filterData;
//   const page = 0, limit = 0
//   const c7_find_pur_order_card_password = 'rVsyKFWYRKo73DqDyGQxeqrEP2xocq1ntuS8Nfbi'

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${WEB_API_URL}/c7_find_pur_order_card`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ password: c7_find_pur_order_card_password, uuid, page, limit }),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();      
//       return { listResponse: listResponse, status: response.status };
//     }
//     const {status, statusText} = response
//     throw new Error({status, statusText});

//   } catch (error) {
//     console.log(error)
//     return error;
//   }  
// }

// export async function web_40_credit_list(filterData) {
//   const { cookie_uuid, cookie_hash, page, limit } = filterData;

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${WEB_API_URL}/web_40_credit_list`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, page, limit }),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();
//       return { listResponse: listResponse, status: response.status };
//     }
//     const { status, statusText } = response
//     throw new Error({ status, statusText });

//   } catch (error) {
//     console.log(error)
//     return error;
//   }
// }

export async function web_42_order_history(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, start_date, end_date, page, limit } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_42_order_history`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, start_time: start_date, end_time: end_date, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

// export async function C12_Order_Detail(filterData) {
//   const {cookie_uuid, cookie_hash, ordernumber} = filterData;

//   // const C12_Order_Detail_password = ''

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${WEB_API_URL}/C12_Order_Detail`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber }),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();      
//       return { listResponse: listResponse, status: response.status };
//     }
//     const {status, statusText} = response
//     throw new Error({status, statusText});

//   } catch (error) {
//     console.log(error)
//     return error;
//   }  
// }

export async function web_29_Order_Detail(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_29_Order_Detail`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_29_ncc_Order_Detail(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_29_ncc_Order_Detail`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_02_get_menu(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  // const web_02_get_menu_password = ''

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_02_get_menu`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {     
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_05_exception_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData;

  // const web_05_exception_order_password = ''

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_05_exception_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, notify_message: "", order_number: ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_07_change_password(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, user_password, user_old_password, verification_code } = filterData;

  // const web_07_change_password_password = ''

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_07_change_password`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, user_password, user_old_password, verification_code }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_08_share_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, cardnumber, start_date, end_date, state, page, limit } = filterData;

  // const C12_Order_Detail_password = ''

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_08_share_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, name_or_cardnumber: cardnumber, start_time: start_date, end_time: end_date, state, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_10_add_account(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, staffuser, staffname, staffpassword, percen, hierarchy, percen_json } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_10_add_account`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, staffuser, staffpassword, staffname, percen, hierarchy, percen_json }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

// export async function sms_api_host(filterData) {
//   const {cookie_uuid} = filterData;

//    const sms_api_host_password = "7yb37VpWGbBkQ2Dpg778ExXBfZBKeAqt"

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${SMS_API_URL}/get_wx_host_qr`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({smspasswd: sms_api_host_password, uuid: cookie_uuid}),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();      
//       return { listResponse: listResponse, status: response.status };
//     }
//     const {status, statusText} = response
//     throw new Error({status, statusText});

//   } catch (error) {
//     console.log(error)
//     return error;
//   }  
// }

export async function web_19_review_staffcardnumber(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_19_review_staffcardnumber`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

// export async function g2_exception_pur_sub_order(filterData) {
//   const {ordernumber} = filterData;

//   const g2_exception_pur_sub_order_password = "b2*=v4?%p2p.s2pi4!vxk+arg*!qfkub"

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${WEB_API_URL}/exception_pur_sub_order`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({password: g2_exception_pur_sub_order_password, excpsopur_rawordernumber: ordernumber}),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();      
//       return { listResponse: listResponse, status: response.status };
//     }
//     const {status, statusText} = response
//     throw new Error({status, statusText});

//   } catch (error) {
//     console.log(error)
//     return error;
//   }  
// }

export async function web_36_flowrecord_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, serial, start_date, end_date, transtype, operatetype, page, limit, field } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_36_flowrecord_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, serial, start_date, end_date, transtype, operatetype, page, limit, field }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_22_get_card_to_add_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_22_get_card_to_add_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_15_add_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, uuid, ordernumber, productprice, payername, staffname, staffbankname, staffcardnumber, merchantno, confirmpeople, source } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_15_add_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, web_15_order_uuid: uuid, web_15_ordernumber: ordernumber, web_15_productprice: productprice, web_15_payername: payername,
        web_15_staffname: staffname, web_15_staffbankname: staffbankname, web_15_staffcardnumber: staffcardnumber, web_15_merchantno: merchantno, web_15_confirmpeople: confirmpeople,
        web_15_source: source
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

// export async function f4_add_order(filterData) {  
//   const {uuid, ordernumber, productprice, payername, staffname, staffbankname, staffcardnumber, merchantno, confirmpeople, source} = filterData;

//   const f4_add_order_password = '7S4Xm6F2DD4+?D8Cz3TE@-5RuhvHtz-7'

//   try {
//     const controller = new AbortController();
//     const time = setTimeout(() => controller.abort(), 30000);

//     const response = await fetch(`${WEB_API_URL}/add_order`, {
//       method: "POST",
//       signal: controller.signal,
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({f4_password: f4_add_order_password, f4_uuid: uuid, f4_ordernumber: ordernumber, f4_productprice: productprice, f4_payername: payername,
//         f4_staffname: staffname, f4_staffbankname: staffbankname, f4_staffcardnumber: staffcardnumber, f4_merchantno: merchantno, f4_confirmpeople: confirmpeople,
//         f4_source: source
//       }),
//     });
//     clearTimeout(time);
//     if (response.ok) {
//       const listResponse = await response.json();      
//       return { listResponse: listResponse, status: response.status };
//     }
//     const {status, statusText} = response
//     throw new Error({status, statusText});

//   } catch (error) {
//     console.log(error)
//     return error;
//   }  
// }

export async function web_18_get_merchant(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_18_get_merchant`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_33_add_pur_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, customerno, amount, merchantno, bankname, accountname, bankbranch, bankcity, bankcardno, confirmpeople, source } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_33_add_pur_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, web_33_pur_customerno: customerno, web_33_pur_amount: amount, web_33_pur_merchantno: merchantno, web_33_pur_bankname: bankname,
        web_33_pur_accountname: accountname, web_33_pur_bankbranch: bankbranch, web_33_pur_bankcity: bankcity, web_33_pur_bankcardno: bankcardno, web_33_pur_confirmpeople: confirmpeople,
        web_33_pur_source: source
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_28_create_pur_sub_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_28_create_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_14_find_pur_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, grab } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_14_find_pur_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, grab }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_21_find_pur_order_card(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_21_find_pur_order_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_productamount: "0", merchantno: "", select }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function get_income_data(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, qr_uuid } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${SMS_API_URL}/get_income_data`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, qr_uuid }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_03_complete_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, orderprice, staffcardnumber, confirmpeople, merchantno, notifyurl, notify_message} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_03_complete_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber, orderprice, staffcardnumber, web_03_confirmpeople: confirmpeople, merchantno, notifyurl, notify_message}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_35_pur_ordernumber_get_suborder(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, pur_ordernumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_35_pur_ordernumber_get_suborder`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_39_find_user_card(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, page, limit } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_39_find_user_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_32_retract_pur_raw_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, confirmpeople, merchantno, ordernumber, amount, notifyurl, retpro_pur_pair_state } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_32_retract_pur_raw_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, retpro_confirmpeople: confirmpeople, retpro_pur_merchantno: merchantno,
        retpro_pur_ordernumber: ordernumber, retpro_pur_amount: amount, retpro_pur_notifyurl: notifyurl, retpro_pur_pair_state: retpro_pur_pair_state
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_25_confirm_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, rawordernumber, orderprice, staffcardnumber, confirmpeople } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_25_confirm_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, conpso_pur_ordernumber: ordernumber, conpso_pur_rawordernumber: rawordernumber,
        conpso_pur_orderprice: orderprice, conpso_pur_staffcardnumber: staffcardnumber, conpso_pur_confirmpeople: confirmpeople
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_26_retract_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_26_retract_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, retpso_pur_ordernumber: ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_30_get_transfer_data(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, productamount } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_30_get_transfer_data`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, seatd_pur_productamount: productamount }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_31_transfer_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, confirmpeople, ordernumber, staffname, staffbankname, staffcardnumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_31_transfer_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, trapso_confirmpeople: confirmpeople, trapso_pur_ordernumber: ordernumber,
        trapso_pur_staffname: staffname, trapso_pur_staffbankname: staffbankname, trapso_pur_staffcardnumber: staffcardnumber
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_24_exception_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_24_exception_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, excpsopur_rawordernumber: ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function get_wx_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, search } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${SMS_API_URL}/get_wx_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, search }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_13_matched_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, confirmpeople, goal, max_split_num, min_split_amount, card } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_13_matched_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, matpso_pur_confirmpeople: confirmpeople, matpso_pur_goal: goal,
        matpso_pur_max_split_num: max_split_num, matpso_pur_min_split_amount: min_split_amount, matpso_pur_card: card
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function get_wx_host_qr(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, qr_uuid } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${SMS_API_URL}/get_wx_host_qr`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, qr_uuid }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_52_get_all_uuid(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_52_get_all_uuid`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function get_available_wx_host(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, qr_uuid } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${SMS_API_URL}/get_available_wx_host`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, qr_uuid }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function pair_wx(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, wx_host, sec_name, wx_uuid } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${SMS_API_URL}/pair_wx`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, wx_host, sec_name, wx_uuid }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_49_update_shop_am_record_state(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, state } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_49_update_shop_am_record_state`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, serial: ordernumber, state }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_27_create_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, crepso_pur_confirmpeople, crepso_pur_merchantno, crepso_pur_rawordernumber,
    crepso_pur_productamount, crepso_pur_payeebankname, crepso_pur_payeebankcity, crepso_pur_payeebankbranch,
    crepso_pur_payeename, crepso_pur_payeecardnumber, crepso_carddata
  } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_27_create_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, crepso_pur_confirmpeople, crepso_pur_merchantno, crepso_pur_rawordernumber,
        crepso_pur_productamount, crepso_pur_payeebankname, crepso_pur_payeebankcity, crepso_pur_payeebankbranch,
        crepso_pur_payeename, crepso_pur_payeecardnumber, crepso_carddata
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_43_wx_get_keyword(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_43_wx_get_keyword`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_44_insert_bank_key(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, bank_wxid, keywd } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_44_insert_bank_key`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, bank_wxid, keywd }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_45_wx_get_bank_wxid(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_45_wx_get_bank_wxid`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_50_get_bank_name(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_50_get_bank_name`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function unbind_wx(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, unbind_wx } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${SMS_API_URL}/unbind_wx`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, unbind_wx }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_04_complete_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, img1, img2 } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_04_complete_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, compso_pur_ordernumber: ordernumber, compso_pur_img1: img1, compso_pur_img2: img2 }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_51_get_functionToggle(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_51_get_functionToggle`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_53_update_functionToggle(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, value } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_53_update_functionToggle`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, value }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_54_get_user_upper_and_down_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_54_get_user_upper_and_down_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_56_edit_percen(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, percen_uuid, percen } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_56_edit_percen`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, percen_uuid, percen }),
    });
    clearTimeout(setTimeoutId);
const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_55_insert_erp_water168_shop_bank(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, bank_name } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_55_insert_erp_water168_shop_bank`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, bank_name }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_57_set_card(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, staffcardnumber, paymentamountdaylimit_small, paymentcount, balance,
    paymentamountdaylimit, paymentcountdaylimit, old_paymentamountdaylimit_small, old_paymentcount,
    old_balance, old_paymentamountdaylimit, old_paymentcountdaylimit, priority, state } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_57_set_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, staffcardnumber, paymentamountdaylimit_small, paymentcount, balance,
        paymentamountdaylimit, paymentcountdaylimit, old_paymentamountdaylimit_small, old_paymentcount,
        old_balance, old_paymentamountdaylimit, old_paymentcountdaylimit, priority, state
      }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_59_del_staffcardnumber(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, card_uuid, staffcardnumber } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_59_del_staffcardnumber`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, card_uuid, staffcardnumber }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_60_review_staffcardnumber(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, cardnumber, state } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_60_review_staffcardnumber`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, review: state, staffcardnumber: cardnumber }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_61_verification_code(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, update_uuid, state } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_61_verification_code`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, update_uuid, state }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_11_boss_add_account(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, choose_uuid, staffuser, staffname, staffpassword, percen, merchantno, hierarchy, percen_json } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_11_boss_add_account`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, choose_uuid, staffuser, staffpassword, staffname, percen, merchantno, hierarchy, percen_json }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_63_credit_table_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, page, limit } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_63_credit_table_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, page, limit }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_62_updata_credit(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, update_uuid, old_credittotal, old_creditbalance, credittotal, creditbalance } = filterData

  try {
    const controller = new AbortController();
    const setTimeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(`${WEB_API_URL}/web_62_updata_credit`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, update_uuid, old_credittotal, old_creditbalance, credittotal, creditbalance }),
    });
    clearTimeout(setTimeoutId);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_65_withdrawal_yesorno(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, state } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_65_withdrawal_yesorno`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, state }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_64_share_list(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, user_uuid, start_time, end_time } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_64_share_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, user_uuid, start_time, end_time }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_48_withdrawal(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, withdrawal_amount, bankname, staffname, staffcardnumber, remark } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_48_withdrawal`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, withdrawal_amount, bankname, staffname, staffcardnumber, remark }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_66_get_user_upper_and_down_list(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_66_get_user_upper_and_down_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_67_edit_percent_down_line(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, user_uuid, percen, percent_down_line } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_67_edit_percent_down_line`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, user_uuid, percen, percent_down_line }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_68_logout_all(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_68_logout_all`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_70_purchase_order_reversal(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, pur_ordernumber } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_70_purchase_order_reversal`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

// 1流水 2訂單 3卡片 4採購 5用戶
export async function web_69_get_report(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, reportType, start_date, end_date, typeName} = filterData;


  try {

    let filename = ""
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 20000);

    const response = await fetch(`${WEB_API_URL}/web_69_get_report`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json,application/octet-stream",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, web_69_id: reportType, web_69_startdate: start_date, web_69_enddate: end_date }),


    }).then(res => {
      clearTimeout(time)
      // console.log(res.headers.forEach(item => console.log(item)));
      // const contentDispositionHeader = res.headers.get('content-disposition');

      // if (contentDispositionHeader) {
      //   const matches = contentDispositionHeader.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?$/i);

      //   if (matches && matches.length > 1) {
      //     filename = decodeURIComponent(matches[1]);
      //     console.log("Filename:", filename);
      //   }
      // } else {
      //   console.log("Content-Disposition header not found.");
      // }
      return res.blob();
    }).then(blob => {
      filename = `${typeName}_${new Date(Number(start_date)).toLocaleDateString()}-${new Date(Number(end_date)).toLocaleDateString()}`
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(a.href); // 释放URL 对象
      a.remove();

    })
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_71_flowrecord_update(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, merchantno, staffcardnumber, orderprice, operatetype, transtype, remark } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_71_flowrecord_update`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, merchantno, staffcardnumber, orderprice, operatetype, transtype, remark }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_72_all_card_to_3_update(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_72_all_card_to_3_update`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_73_get_bonus_set(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_73_get_bonus_set`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_74_update_bonus_set(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, bonus_per, bonus_hight_thre, bonus_low_thre, start_date, end_date } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_74_update_bonus_set`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, bonus_per, bonus_hight_thre, bonus_low_thre, start_date, end_date }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_17_add_staffcardnumber(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, staffcardnumber, staffname, bankname, bankprovince, bankcity,
    bankbranch, paymentamountdaylimit, paymentcountdaylimit, pur_payment_limit, img_url } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_17_add_staffcardnumber`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, staffcardnumber, staffname, bankname,
        bankprovince, bankcity, bankbranch, paymentamountdaylimit, paymentcountdaylimit, pur_payment_limit, img_url
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function uploadfile(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const url = new URL(`${FILE_API_URL}/uploadfile/`);
  const password = "7S4Xm6F2DD4+?D8Cz3TE@-5RuhvHtz-7"

  // 添加查询参数
  url.searchParams.append('password', password);

  const { fileImg } = filterData;
  const formData = new FormData()


  if (fileImg) formData.append("file", fileImg)
  // if(fileImg2) formData.append("file", fileImg2)
  console.log("fileImg: ", fileImg);
  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 120000);

    const response = await fetch(url, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json"
      },
      body: formData
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_75_get_merchantno(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_75_get_merchantno`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_16_information_board(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, start_date, end_date } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_16_information_board`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, start_date, end_date }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_76_get_marquee(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, allornot } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_76_get_marquee`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, allornot }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_77_insert_marquee(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, message, start_time, end_time, state } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_77_insert_marquee`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, message, start_time, end_time, state }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_79_get_menu(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_79_get_menu`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_78_update_menu(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, data } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_78_update_menu`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, data }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_80_reset_menu(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_80_reset_menu`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_81_get_gateway(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, merchantno, select_state, select_type } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_81_get_gateway`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, merchantno, select_state, select_type }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_82_create_gateway(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, gateway_type_id, display_name, merchantno, col_rate_percent,
    col_rate_fixe, pur_rate_percent, pur_rate_fixe, min_fee, max_fee, min_limit_per_transaction,
    max_limit_per_transaction, state} = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_82_create_gateway`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, gateway_type_id, display_name, merchantno, col_rate_percent,
        col_rate_fixe, pur_rate_percent, pur_rate_fixe, min_fee, max_fee, min_limit_per_transaction,
        max_limit_per_transaction, state }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_83_edit_gateway(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, display_name, gateway_type_id, merchantno, col_rate_percent,
    col_rate_fixe, pur_rate_percent, pur_rate_fixe, min_fee, max_fee, min_limit_per_transaction,
    max_limit_per_transaction, state} = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_83_edit_gateway`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, display_name, gateway_type_id, merchantno, col_rate_percent,
        col_rate_fixe, pur_rate_percent, pur_rate_fixe, min_fee, max_fee, min_limit_per_transaction,
        max_limit_per_transaction, state }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_84_add_flowrecord_get_merchantno(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_84_add_flowrecord_get_merchantno`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_40_merchantno_money(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_40_merchantno_money`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_41_am_record(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ori_amount, change_amount, remark } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_41_am_record`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ori_amount, change_amount, remark }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_47_disable_splitting_of_purchase_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_47_disable_splitting_of_purchase_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber }),
    });
    clearTimeout(time);    
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_12_ncc_orderlist(filterData) {
  
  filterData = convertNumbersToStrings(filterData)

  const { cookie_uuid, cookie_hash, ordernumber, start_date, end_date, page, limit, field } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_12_ncc_orderlist`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber, start_date, end_date, page, limit, field}),
    });
    clearTimeout(time);    
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_03_ncc_complete_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_03_ncc_complete_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_05_ncc_exception_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData;  

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_05_ncc_exception_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, notify_message: "", order_number: ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_15_ncc_add_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { uuid, token, order_uuid, ordernumber, productprice, payer_info, staff_info, merchantno, confirmpeople, source, gateway_id, verification_code } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_15_ncc_add_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid, token, order_uuid, ordernumber, productprice, payer_info, staff_info, merchantno, confirmpeople, source, gateway_id, verification_code
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_22_ncc_get_info_to_add_ncc_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_22_ncc_get_info_to_add_ncc_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_23_ncc_get_gateway(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id_list } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_23_ncc_get_gateway`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id_list }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_85_get_erp_water168_shop_merchant_data(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_85_get_erp_water168_shop_merchant_data`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_39_ncc_find_user_ncc_set(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, select, page, limit, card } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_39_ncc_find_user_ncc_set`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, page, limit, card }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_17_ncc_add_info(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, staffname, info, payee_name, paymentamountdaylimit, single_payment_upper_limit, single_payment_lower_limit, img_url,
    gateway_type_id, gateway } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_17_ncc_add_info`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, staffname, info, payee_name, paymentamountdaylimit, single_payment_upper_limit, single_payment_lower_limit, img_url,
        gateway_type_id, gateway }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_17_ncc_gateway_type(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, cardorncc } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_17_ncc_gateway_type`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, cardorncc }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_17_ncc_gateway_list(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, gateway_type } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_17_ncc_gateway_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, gateway_type }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_57_ncc_set_card(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, paymentamountdaylimit, single_payment_upper_limit, single_payment_lower_limit,
  balance, info, old_paymentamountdaylimit, old_single_payment_upper_limit, old_single_payment_lower_limit,
  old_balance, old_info, state } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_57_ncc_set_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, paymentamountdaylimit, single_payment_upper_limit, single_payment_lower_limit,
        balance, info, old_paymentamountdaylimit, old_single_payment_upper_limit, old_single_payment_lower_limit,
        old_balance, old_info, state }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_19_ncc_review_info(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, card } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_19_ncc_review_info`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, card }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_38_update_user_hierarchy(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, update_uuid, hierarchy, botton_type } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_38_update_user_hierarchy`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, update_uuid, hierarchy, botton_type }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_58_get_revenue_sharing(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, search_uuid } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_58_get_revenue_sharing`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, search_uuid }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_56_ncc_edit_percen(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, percen_uuid, percen, gateway_id} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_56_ncc_edit_percen`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, percen_uuid, percen, gateway_id}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_60_ncc_review_ncc(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, review} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_60_ncc_review_ncc`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, review}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_17_ncc_show_nccset_gateway(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select_uuid} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_17_ncc_show_nccset_gateway`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select_uuid}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_17_ncc_edit_nccset_gateway(filterData) {

  const { cookie_uuid, cookie_hash, data} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_17_ncc_edit_nccset_gateway`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, data}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_86_get_brushing_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_86_get_brushing_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_87_update_brushing_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, limit_duration, limit_duration_again, acquisition_amount_seconds, acquisition_amount,
    acquisition_number_seconds, acquisition_number, paid_amount_seconds, paid_amount, paid_number_seconds,
    paid_number, unpaid_amount_seconds, unpaid_amount, unpaid_number_seconds, unpaid_number} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_87_update_brushing_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, limit_duration, limit_duration_again, acquisition_amount_seconds, acquisition_amount,
        acquisition_number_seconds, acquisition_number, paid_amount_seconds, paid_amount, paid_number_seconds,
        paid_number, unpaid_amount_seconds, unpaid_amount, unpaid_number_seconds, unpaid_number}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_88_pur_order_allotment_or_grab(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, grab, verification_code} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_88_pur_order_allotment_or_grab`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, id, grab, verification_code}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_89_channel_non_card_to_channel_card(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, operating_uuid, card_id} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_89_channel_non_card_to_channel_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, operating_uuid, card_id}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_90_total_ncc_balances(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, operating_uuid} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_90_total_ncc_balances`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, operating_uuid}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_27_ncc_create_pur_sub_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, crepso_pur_confirmpeople, crepso_pur_rawordernumber,
    crepso_pur_productamount, crepso_pur_payee_info, crepso_carddata, verification_code, def3pname } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_27_ncc_create_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, crepso_pur_confirmpeople, crepso_pur_rawordernumber,
        crepso_pur_productamount, crepso_pur_payee_info, crepso_carddata, verification_code, def3pname
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_14_ncc_find_pur_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, grab, nccor3p = "0" } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_14_ncc_find_pur_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, grab, nccor3p }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_09_ncc_pur_orderlist(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, start_date, end_date, page, limit, field } = filterData;
  
  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_09_ncc_pur_orderlist`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, start_date, end_date, page, limit, field }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_33_ncc_add_pur_order(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, payer_info, amount, customerno, merchantno, gateway_id, grab, confirmpeople, source, verification_code } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_33_ncc_add_pur_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, web_33_ncc_pur_payer_info: payer_info, web_33_ncc_pur_amount: amount, web_33_ncc_pur_customerno: customerno, web_33_ncc_pur_merchantno: merchantno, web_33_ncc_pur_gateway_id: gateway_id,
        web_33_ncc_pur_grab: grab, web_33_ncc_pur_confirmpeople: confirmpeople, web_33_ncc_pur_source: source, verification_code
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_35_ncc_pur_ordernumber_get_suborder(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, pur_ordernumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_35_ncc_pur_ordernumber_get_suborder`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_04_ncc_complete_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, img1, img2 } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_04_ncc_complete_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, compso_pur_ordernumber: ordernumber, compso_pur_img1: img1, compso_pur_img2: img2 }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_32_ncc_retract_pur_raw_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, confirmpeople, merchantno, ordernumber, amount, notifyurl, retpro_pur_pair_state } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_32_ncc_retract_pur_raw_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, retpro_confirmpeople: confirmpeople, retpro_pur_merchantno: merchantno,
        retpro_pur_ordernumber: ordernumber, retpro_pur_amount: amount, retpro_pur_notifyurl: notifyurl, retpro_pur_pair_state: retpro_pur_pair_state
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_24_ncc_exception_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_24_ncc_exception_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, excpsopur_rawordernumber: ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_25_ncc_confirm_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, rawordernumber, orderprice, confirmpeople } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_25_ncc_confirm_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: cookie_uuid, token: cookie_hash, conpso_pur_ordernumber: ordernumber, conpso_pur_rawordernumber: rawordernumber,
        conpso_pur_orderprice: orderprice, conpso_pur_confirmpeople: confirmpeople
      }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_26_ncc_retract_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_26_ncc_retract_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, retpso_pur_ordernumber: ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_21_ncc_find_pur_order_card(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, nccor3p, pur_gateway_id} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_21_ncc_find_pur_order_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_productamount: "0", select, nccor3p, pur_gateway_id }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_30_ncc_get_transfer_data(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, seatd_pur_productamount, gateway_id } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_30_ncc_get_transfer_data`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, seatd_pur_productamount, gateway_id }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_31_ncc_transfer_pur_sub_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, trapso_confirmpeople, trapso_pur_ordernumber, trapso_pur_staff_info } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_31_ncc_transfer_pur_sub_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, trapso_confirmpeople, trapso_pur_ordernumber, trapso_pur_staff_info}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_93_get_3pset(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_93_get_3pset`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_92_edit_3pset(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, id, new_3_pset_uuid, new_3_pset_username, new_3_pset_secondname, representscardnumber,
    paymentamountdaylimit, paymentcountdaylimit, pur_merchantno, col_merchantno, def_list, state, gateway_id_list } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_92_edit_3pset`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, id, new_3_pset_uuid, new_3_pset_username, new_3_pset_secondname, representscardnumber,
        paymentamountdaylimit, paymentcountdaylimit, pur_merchantno, col_merchantno, def_list, state, gateway_id_list }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_94_get_3pset_uuid_card(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_94_get_3pset_uuid_card`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_95_get_3pset_def(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, gateway_type_id } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_95_get_3pset_def`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, gateway_type_id }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_96_get_collect_asyncnotify(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, page, limit, table_type, select } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_96_get_collect_asyncnotify`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, page, limit, table_type, select }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_97_update_collect_asyncnotify(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, merchantno, amount, table_type } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_97_update_collect_asyncnotify`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, ordernumber, merchantno, amount, table_type }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_98_new_brushing_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, brushing_type, depositname, block_second, note, information} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_98_new_brushing_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, brushing_type, depositname, block_second, note, information }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_99_get_block_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_99_get_block_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_100_frozen_amount(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, operatetype, orderprice, remark} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_100_frozen_amount`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, ordernumber, operatetype, orderprice, remark }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_101_get_frozen_amount(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber, page, limit, s_time, e_time} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_101_get_frozen_amount`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, ordernumber, page, limit, s_time, e_time }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_102_get_merchant_list(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select, page, limit } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_102_get_merchant_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, select, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_103_new_merchant(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, name, login_ip_whitelist, payment_ip_whitelist,
     withdrawal_ip_whitelist, cashier_version, col_state, pur_state, tg_chatid, xrate, mediator } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_103_new_merchant`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, name, login_ip_whitelist, payment_ip_whitelist,
         withdrawal_ip_whitelist, cashier_version, col_state, pur_state, tg_chatid, xrate, mediator }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_104_update_merchant(filterData) {

  filterData = convertNumbersToStrings(filterData)

  const { cookie_uuid, cookie_hash, merchant_id, name, login_ip_whitelist, payment_ip_whitelist, withdrawal_ip_whitelist,
     cashier_version, col_state, pur_state, tg_chatid, xrate, mediator } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_104_update_merchant`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, merchant_id, name, login_ip_whitelist,
         payment_ip_whitelist, withdrawal_ip_whitelist, cashier_version, col_state, pur_state, tg_chatid, xrate, mediator }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_105_update_brushing_order(filterData) {

  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, old_depositname, depositname, block_second, note} = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_105_update_brushing_order`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({uuid: cookie_uuid, token: cookie_hash, old_depositname, depositname, block_second, note}),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_71_ncc_flowrecord_update(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, merchantno, info, orderprice, operatetype, remark, gateway } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_71_ncc_flowrecord_update`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, merchantno, info, orderprice, operatetype, remark, gateway }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_106_get_successful_orders(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, merchant, timestamp_0days, timestamp_30days, timestamp_0h, timestamp_24h, getType } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_106_get_successful_orders`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "public, max-age=60"
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, merchant, timestamp_0days, timestamp_30days, timestamp_0h, timestamp_24h, getType }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_107_update_sys_cost(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, collection_system_percentage, collection_system_fixed_fee,
  payment_system_percentage, payment_system_fixed_fee } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_107_update_sys_cost`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, collection_system_percentage, collection_system_fixed_fee,
        payment_system_percentage, payment_system_fixed_fee }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_108_get_sys_cost(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_108_get_sys_cost`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_109_agency_transaction(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, s_time, e_time } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_109_agency_transaction`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, s_time, e_time }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_70_ncc_purchase_order_reversal(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, pur_ordernumber } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_70_ncc_purchase_order_reversal`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, pur_ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_110_pur_order_ncc_re_back(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, ordernumber } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_110_pur_order_ncc_re_back`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, ordernumber }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_111_get_3p_def_statistical(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, stime, etime } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_111_get_3p_def_statistical`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, stime, etime }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_112_update_3p_def(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, display_name, purchase_quota, def3pname } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_112_update_3p_def`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, display_name, purchase_quota, def3pname }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_113_get_lang(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_113_get_lang`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_114_update_lang(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, value, id } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_114_update_lang`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, value, id }),
    });
    clearTimeout(time);    
    const listResponse = await response.json();
    console.log("response: ", response);
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_115_select_3p_gateway_list(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, gateway_type_id } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_115_select_3p_gateway_list`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, gateway_type_id }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    } 

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function show_conversation(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { order_id } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${CONVER_API_URL}/show_conversation?order_id=${order_id}`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",        
      },
      // body: JSON.stringify({ order_id }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function insert_conversation(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { order_id, sender, content } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${CONVER_API_URL}/insert_conversation?order_id=${order_id}&sender=${sender}&content=${content}`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        
      },      
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }   

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_116_select_gateway_share(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, select_uuid, select_gateway } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_116_select_gateway_share`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select_uuid, select_gateway }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }   

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_117_ncc_edit_percen(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, percen_uuid, percen } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_117_ncc_edit_percen`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, percen_uuid, percen }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }  

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_118_update_overdue(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, minute } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_118_update_overdue`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, minute }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }  

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_119_get_overdue(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_119_get_overdue`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_120_update_page_lang(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, lang } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_120_update_page_lang`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, lang }),
    });
    clearTimeout(time);
   const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_121_get_page_lang() {
  
  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_121_get_page_lang`, {
      method: "GET",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    } 

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_122_update_checkout_3p_parameter(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, frequency, seconds } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_122_update_checkout_3p_parameter`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, frequency, seconds }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }  

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_123_get_checkout_3p_parameter(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_123_get_checkout_3p_parameter`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }   

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_124_update_pur_order_overdue(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash, minute } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_124_update_pur_order_overdue`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, minute }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }  

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_125_get_pur_order_overdue(filterData) {
  
  filterData = convertNumbersToStrings(filterData)
  
  const { cookie_uuid, cookie_hash } = filterData;

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_125_get_pur_order_overdue`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }  

  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_126(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, select, card } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_126`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, select, card }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_127(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, count } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_127`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, count }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_128(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_128`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_129(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_129`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_130(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, mediator } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_130`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, mediator }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_131(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, merchantno } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_131`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, merchantno }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_132(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, data } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_132`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, data }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_133(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_133`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_134(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, start_date, end_date, mediator_uuid } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_134`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, start_date, end_date, mediator_uuid }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_135(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, timestamp_0days, timestamp_30days, timestamp_0h, timestamp_24h, gateway_id, getType } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_135`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, timestamp_0days, timestamp_30days, timestamp_0h, timestamp_24h, gateway_id, getType }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_136(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, field, select, page, limit } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_136`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, field, select, page, limit }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function web_137(filterData) {
  
  filterData = convertNumbersToStrings(filterData)  

  const { cookie_uuid, cookie_hash, out_no, status } = filterData

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${WEB_API_URL}/web_137`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid: cookie_uuid, token: cookie_hash, out_no, status }),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "非预期的错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

